
































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { getComponent } from '@/utils/helpers';
import userProfileModule from '@/store/modules/userProfileModule';
import notificationsModule from '@/store/modules/notificationsModule';
import accountModule from '@/store/modules/accountModule';
import reportModule from '@/store/modules/reportModule';
import { getNameByEmail } from '@/utils/users';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {
    UserSettings: () => getComponent('common/UserSettings'),
    Datetime,
  },
})
export default class UserProfile extends Vue {
	savingProfile = false

  savingReport = false

	sendingInvite = false

	firstName = ''

	lastName = ''

	user_role = 'Engineers'

	team = ''

	email = ''

	phoneNumber = ''

	userInviteEmail = ''

	saveSuccessfulNotificationTitle = 'Changes saved'

	saveSuccessfulNotificationSubTitle = 'You may navigate away from this page.'

	acceptingTasqs = true

	showSaveSuccessfulNotification = false

	notifyOnNewJob = true

	notifyOnReassignedTasq = true

	loadingProfilePage = false

	username = ''

	userInitial = ''

   report = {
     period: 'Daily',
     day: '',
     time: new Date(new Date().setHours(0, 0, 0)).toISOString(),
   }

   reports: any = []

   didToggleAcceptingTasqs() {
	  this.updateAcceptingTasqs();
   }

   get getCurrentName(): string {
     return getNameByEmail(workflowModule.user.email);
   }

   get teams() {
     return workflowModule.teams;
   }

   get disableGeneratePDF() {
     return !!(this.report.period === 'Weekly' && this.report.day === '');
   }

   resetButtonTapped() {
     this.firstName = workflowModule.user.firstName;
     this.lastName = workflowModule.user.lastName;
     this.email = workflowModule.user.email;
     this.phoneNumber = workflowModule.user.phoneNumber;
     this.username = workflowModule.user.username;
     this.userInitial = workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0);
     this.user_role = workflowModule.user.role;
   }

   async created() {
     this.loadingProfilePage = true;
     await workflowModule.listTeams();
     await workflowModule.getUserProfile({ username: accountModule.user.email });
     this.loadingProfilePage = false;
     this.firstName = workflowModule.user.firstName;
     this.lastName = workflowModule.user.lastName;
     this.email = workflowModule.user.email;
     this.phoneNumber = workflowModule.user.phoneNumber;
     this.username = workflowModule.user.username;
     this.userInitial = workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0);
     this.user_role = workflowModule.user.role;
     this.reports = await reportModule.getTLReport();


}

   sleep(ms) {
	  return new Promise((resolve) => setTimeout(resolve, ms));
   }

   async sendInvite() {
	  this.sendingInvite = true;
	  await userProfileModule.sendInvitationEmail({
	    user_email: accountModule.user.email,
	    recipient_email: this.userInviteEmail,
	    user_first_name: this.userInviteEmail,
	  }).then(() => {
	    // this.savingProfile = false
	    this.saveSuccessfulNotificationTitle = 'Invitation sent';
	    this.showSaveSuccessfulNotification = true;
	    this.userInviteEmail = '';
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
	  });
	  this.sendingInvite = false;
   }

   async updateNotificationSettings() {
	  // this.savingProfile = true

	  await notificationsModule.updateNotificationSettings({
	    username: accountModule.user.email,
	    notify_on_new_job: this.notifyOnNewJob,
	    notify_on_reassigned_tasq: this.notifyOnReassignedTasq,
	  }).then(() => {
	    // this.savingProfile = false
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
	  });
   }

   openReportTab() {
     window.open(`${window.location.origin}/report/7`);
   }

   async updateAcceptingTasqs() {
	  // this.savingProfile = true

	  await userProfileModule.updateAcceptingTasqs({
	    username: accountModule.user.email,
	    accepting_tasqs: this.acceptingTasqs,
	  }).then(() => {
	    // this.savingProfile = false
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
	  });
   }

   async saveTLReport() {
     this.savingReport = true;
     await reportModule.generateTLReport(this.report).then(async () => {
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.savingProfile = false;
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
       this.reports = await reportModule.getTLReport();
       this.savingReport = false;
	  });
   }

   async deleteTLReport({ TLReportID }) {
     await reportModule.deleteTLReport(TLReportID).then(async () => {
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.savingProfile = false;
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
       this.reports = await reportModule.getTLReport();
	  });
   }

   async saveProfile() {
	  this.savingProfile = true;

	  await userProfileModule.updateUserProfile({
	    username: accountModule.user.email,
	    accepting_tasqs: true,
	    first_name: this.firstName,
	    last_name: this.lastName,
	    phone_number: this.phoneNumber,
       team: this.team,
	    roles: [this.user_role],
	    user_email: accountModule.user.email,
	  }).then(() => {
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.savingProfile = false;
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
       accountModule.getReassignmentList();
	  });
   }
}
