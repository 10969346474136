import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import TrackedIssue from '@/interfaces/analytics/TrackedIssue';
import getTrackedIssuesResponse from '@/graphql/userSettings/queries/getTrackedIssuesResponse.graphql';
import workflowApolloClient from '@/lib/appsync/workflow';
import updateUserProfile from '@/graphql/userSettings/mutations/updateUserProfile.graphql';
import sendInvitationEmail from '@/graphql/userSettings/mutations/sendInvitationEmail.graphql';
import accountModule from '@/store/modules/accountModule';
import workflowModule from './workflowModule';
import tasqsListModule from './tasqsListModule';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'userProfileModule',
  store,
})
class UserProfileModule extends VuexModule {
//   availableSignals = getConfigEnv('SIGNALS_ARRAY');

  //   @Mutation
  //   resetIssues(): void {
  //     this.userIssues = [];
  //   }

  //   @Mutation
  //   addIssue(issue): void {
  //     this.userIssues.push(issue);
  //   }

  //   @Mutation
  //   addTrackedIssue(issue): void {
  //     this.userIssues.push(issue);
  //   }

  // operator: String!
  // recipient_email: String!
  // user_email: String!
  // user_first_name: String!
  // user_id: String

@Action
  async sendInvitationEmail({
    recipient_email,
    user_email,
    user_first_name,
  }) {
    try {
	  const {
        data: {
          send_invitation_email: {
		  },
        },
	  } = await workflowApolloClient.mutate({
        mutation: sendInvitationEmail,
        variables: {
		  input: {
            recipient_email,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            user_first_name,
            user_email,
		  },
        },
	  });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
async updateUserProfile({
  accepting_tasqs,
  first_name,
  last_name,
  phone_number,
  team,
  roles,
  user_email,
  username,
}) {
  try {
    const {
      data: {
        update_user_profile: {
        },
      },
    } = await workflowApolloClient.mutate({
      mutation: updateUserProfile,
      variables: {
        input: {
			  accepting_tasqs,
          first_name,
          last_name,
			  operator: getConfigEnv('OPERATOR_LOWERCASED'),
			  phone_number,
        	  team,
			  roles,
			  user_email,
			  username,

        },
      },
    });

	if (roles.length > 0) {
		accountModule.setUserRole(roles[0])
		workflowModule.setUserRole(roles[0])
		tasqsListModule.resetTasqListLevel() 
	}
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

  @Action
  async updateAcceptingTasqs({
    accepting_tasqs,
    username,
  }) {
    try {
      const {
        data: {
          update_user_profile: {
          },
        },
      } = await workflowApolloClient.mutate({
        mutation: updateUserProfile,
        variables: {
          input: {
			  accepting_tasqs,
			  operator: getConfigEnv('OPERATOR_LOWERCASED'),
			  username,

          },
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }
}

export default getModule(UserProfileModule);
